import { mapData2num, toNum, unionFiles } from '@/utils/utils'
import { changeLotById, createLot, setFileLots } from '@/http/lot/lotApi'
import { computed, ref, watch } from 'vue'
import { LOT_CRITERION } from '@/utils/lot/const'
import { formatCurrency } from '@/utils/format/number'

export function checkKeepZero (number) {
  const string = String(number)

  if (string[0] !== '0') return number

  if (string[1] === '.') return number

  const sliceString = string.slice(1, 3)

  if (string[1] === '0') {
    return sliceString.slice(1, 2)
  }

  return sliceString
}

export async function sendFiles (formData, store) {
  const formFile = new FormData()
  unionFiles(formData.supportingDocumentation, null, formFile)
  return await setFileLots(store.getters['lot/getLot'].id, formFile)
}

function splitDate (date) {
  const dateList = date.split('T')
  return dateList[0] + ' ' + dateList[1].slice(0, 8)
}

export function changeDateOnUTFFormat (date) {
  const FOUR_HOURS = 18000000
  let currentDate = null
  if (!date) {
    currentDate = new Date(new Date().getTime() + FOUR_HOURS).toISOString()
    return splitDate(currentDate)
  }
  currentDate = new Date(new Date(date).getTime() + FOUR_HOURS).toISOString()
  return splitDate(currentDate)
}

export function setParametersIsSelected (formData) {
  if (!formData.table_criterion?.length) return
  if (!formData.positions?.length) return

  const criterionLength = formData.table_criterion.length

  for (const position of formData.positions) {
    let count = criterionLength

    for (const parameter of position.parameters) {
      if (!count) break

      switch (parameter.full_name) {
        case LOT_CRITERION.valuta: {
          parameter.is_selected = formData.currency_id
          count -= 1
          break
        }
        case LOT_CRITERION.delivery: {
          parameter.is_selected = formData.delivery_condition_id
          count -= 1
          break
        }
        case LOT_CRITERION.payment: {
          parameter.is_selected = formData.payment_term_id
          count -= 1
          break
        }
        case LOT_CRITERION.delayMax: {
          parameter.options[0].name = formData.deley_max
          count -= 1
        }
      }
    }
  }
}

export function transformDataForSend (formData, data) {
  data.divisibility = data.divisibility === 1
  data.start_price = +data.start_price
  data.mit_step = checkKeepZero(formData.min_step)
  data.max_step = checkKeepZero(formData.max_step)
  data.started_at = changeDateOnUTFFormat(formData.started_at)
  data.duration = changeDateOnUTFFormat(formData.duration)
  data.nds_counter = String(formData.nds_counter)
  if (data.isPublishedBeforeSave) {
    data.published_at = changeDateOnUTFFormat(new Date())
  } else {
    data.published_at = changeDateOnUTFFormat(formData.published_at)
  }

  const fields2num = [
    'coef',
    'non_coef'
  ]

  data.table_criterion = data.table_criterion.map(item => mapData2num(item, fields2num))

  data.positions = data.positions.map(position => {
    if (!position.quantity_coef) {
      position.quantity_coef = 0
    }

    if (!position.quantity_ball) {
      position.quantity_ball = 1
    }

    position.parameters = position.parameters.map(item => mapData2num(item, fields2num))

    return position
  })
}

export async function sendCreateLot (formData, data, store) {
  const res = await createLot(data)
  store.commit('lot/setLot', res.data)
  return res
}

export async function sendChangeLot (formData, data, store) {
  data.nds_counter = String(data.nds_counter)
  return await changeLotById(store.getters['lot/getLot'].id, data)
}

export function nowDetailPage (router) {
  return router.currentRoute.value.params?.id
}

export function changeTableInfo (formData) {
  formData.positions.forEach(pos => {
    pos.parameters.forEach(par => {
      if (par.non_coef) {
        par.coef = par.non_coef
      }
    })
  })
}

export function validSendBalls (positions) {
  for (const position of positions) {
    for (const parameter of position.parameters) {
      for (const option of parameter.options) {
        if (option.balls === null) {
          return false
        }
      }
    }
  }

  return true
}

export function validationCoef({ value, nonValue, skill }, tableCriterion, index = null) {
  if (!tableCriterion.length) return

  const groupNames = {
    1: 'Стоимостные',
    2: 'Нестоимостные',
    3: 'Квалификационные'
  }

  const sum = {
    value: 0,
    nonValue: 0,
    skill: 0
  }

  tableCriterion.forEach(criterion => {
    // этот пиздец временное решение, как полфил, для тех элементов у которых не оказалось groupID, а лоты исопльзовать надо
    if (!criterion.groupId) {
      switch (criterion.group_name) {
        case groupNames['1']:
          sum.value += calculateCoef(criterion)
          break
        case groupNames['2']:
          sum.nonValue += calculateCoef(criterion)
          break
        case groupNames['3']:
          sum.skill += calculateCoef(criterion)
      }
    } else {
      switch (criterion.groupId) {
        case 1:
          sum.value += calculateCoef(criterion)
          break
        case 2:
          sum.nonValue += calculateCoef(criterion)
          break
        case 3:
          sum.skill += calculateCoef(criterion)
      }
    }
  })

  if (notValidSum(value, sum.value)) {
    return messageValidationCoef(1, value, index)
  }
  if (notValidSum(nonValue, sum.nonValue)) {
    return messageValidationCoef(2, nonValue, index)
  }
  if (notValidSum(skill, sum.skill)) {
    return messageValidationCoef(3, skill, index)
  }

  function calculateCoef(criterion) {
    return criterion.non_coef ? toNum(criterion.non_coef) : toNum(criterion.coef)
  }

  function notValidSum(valid, sum) {
    return +sum.toFixed(2) !== Number(valid)
  }

  function messageValidationCoef(groupId, sum, index) {
    const groups = {
      1: 'cтоимостных',
      2: 'нестоимостных',
      3: 'квалификационных'
    }

    const numberPosition = typeof index === 'number' ? index + 1 : null
    const place = numberPosition ? `позиции №${numberPosition}` : 'лоте'

    return `Сумма ${groups[groupId]} коэффициентов в ${place} не равна ${sum}`
  }
}

export function criterionDisabled(formData) {
  const minCriterionDisabled = ref(false)
  const maxCriterionDisabled = ref(false)

  const changeMinMaxCriterionDisabled = (boolean) => {
    minCriterionDisabled.value = boolean
    maxCriterionDisabled.value = boolean
  }

  const getPaymentTerm = computed(() => formData.payment_term_id)

  watch(getPaymentTerm, () => {
    if (getPaymentTerm.value) {
      formData.is_criterion_deley_max = false
      changeMinMaxCriterionDisabled(true)
    } else {
      changeMinMaxCriterionDisabled(false)
    }
  })

  return {
    minCriterionDisabled,
    maxCriterionDisabled
  }
}

export function setLotPositionsSum (formData) {
  formData.start_price = 0
  formData.positions.forEach(pos => {
    formData.start_price += (+pos.price * +pos.quantity)
  })

  formData.start_price = formatCurrency(formData.start_price)
}

export function createPatternLotFormData (formData) {
  return {
    pattern: formData,
    name: formData.name,
    procedure_type_id: formData.procedure_type_id,
    nds_counter: String(formData.nds_counter)
  }
}
