import { ERROR_TEXT } from '@/utils/validator/errorText'
import { CUSTOM_HELPERS, checkRequired } from '@/utils/validator/customValidator'
import { maxLength, minLength } from '@vuelidate/validators'
import { createNameBasedOnNumber } from '@/utils/validator/errorValidationMessage'

export class RulesValidationScroll {
  static createRequiredValid (params) {
    return {
      // TODO функция required from '@vuelidate/validators' почему то отрабатывает 1 раз. checkRequired временное решение
      required: CUSTOM_HELPERS.withMessageScroll(ERROR_TEXT.required, params, checkRequired)
    }
  }

  static createMinLengthValid (fieldName, length, params) {
    const message = `Минимальная длина ${fieldName} ${length} ${createNameBasedOnNumber(length)}`
    return {
      minLength: CUSTOM_HELPERS.withMessageScroll(message, params, minLength(length))
    }
  }

  static createMaxLengthValid (fieldName, length, params) {
    const message = `Максимальная длина ${fieldName} ${length} ${createNameBasedOnNumber(length)}`
    return {
      maxLength: CUSTOM_HELPERS.withMessageScroll(message, params, maxLength(length))
    }
  }
}
