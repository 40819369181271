import { api, UrlServices } from '@/http/services/datadata/index'

export const checkAddress = async (query) => {
  const { data } = await api.post(UrlServices.baseUrl, { query: query })
  return data
}

export const checkAddressByFiasID = async (query) => {
  const { data } = await api.post(UrlServices.fiasIdUrl, { query: query })
  return data
}
