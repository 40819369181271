import { computed, onBeforeMount, reactive, watch } from 'vue'
import { getLot } from '@/http/lot/lotApi'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { IntegrationController } from '@/controllers/IntegrationController'
import { refreshArr } from '@/utils/reactive'

export function useGetLot (isEdit, formData, isBidder, pattern = null) {
  const router = useRouter()
  const store = useStore()
  const integrationController = new IntegrationController()

  const listKey = new Map([
    ['table_criterion', () => {
      if (!getLotCP.value?.table_criterion?.length) return
      refreshArr(formData.table_criterion, getLotCP.value.table_criterion)
    }],
    ['positions', () => {
      if (!formData.positions.length) {
        if (pattern) {
          const key = Reflect.has(getLotCP.value, 'position') ? 'position' : 'positions'
          if (!getLotCP.value[key]?.length) return

          const positions = getLotCP.value[key].map(item => ({
            ...item,
            parameters: reactive(item.parameters)
          }))

          refreshArr(formData.positions, positions)
        } else {
          if (getLotCP.value.position?.length) {
            formData.positions.push(...getLotCP.value.position)
          }
        }
      }

      if (isBidder) {
        formData.positions.forEach((position) => {
          position.start_price = position.price
          position.price = null
        })
      }

      formData.positions.forEach(pos => {
        if (!pos.parameters) {
          pos.parameters = reactive([])
        }
        pos.is_quantity_coef = !!pos.parameters.find(item => item.full_name === 'Количество')
      })
    }],
    ['divisibility', () => {
      formData.divisibility = getLotCP.value.divisibility ? 1 : 0
    }],
    ['company_id', () => {
      if (getLotCP.value.company_id) {
        formData.company_id = getLotCP.value.company_id
      }

      if (getLotCP.value.company?.id) {
        formData.company_id = getLotCP.value?.company.id
      }
    }],
    ['supportingDocumentation', () => {
      store.commit('files/setFiles', store.getters['lot/getLot'].file)
    }],
    ['isPublishedBeforeSave', () => {
      formData.isPublishedBeforeSave = !getLotCP.value.published_at
    }],
    ['nds_counter', () => {
      formData.nds_counter = getLotCP.value.nds_counter
      if (getLotCP.value.nds_counter) {
        formData.isNds = true
      }
    }],
    ['is_criterion_payment', () => {
      formData.is_criterion_payment = getIsCriterion('Условия оплаты')
    }],
    ['is_criterion_delivery', () => {
      formData.is_criterion_delivery = getIsCriterion('Условия доставки')
    }],
    ['is_criterion_valute', () => {
      formData.is_criterion_valute = getIsCriterion('Валюта')
    }],
    ['deley_max', () => {
      formData.deley_max = getLotCP.value.deley_max
      formData.deleyMaxForBidder = getLotCP.value.deley_max
    }]
  ])

  const getIsCriterion = (name) => {
    return !!getLotCP.value.table_criterion?.find(item => item.full_name === name)
  }

  const isLayoutView = () => {
    return router.currentRoute.value.fullPath.includes('layout')
  }

  onBeforeMount(async () => {
    if (isLayoutView()) {
      if (pattern) {
        store.commit('lot/setLot', pattern.pattern)
      }
      isEdit.value = false
      return
    }
    if (router.currentRoute.value.params?.id) {
      isEdit.value = true
      try {
        if (!isBidder) {
          await getCurrentLot()
        }
        const userLotId = store.getters['lot/getLot'].user?.id
        if (userLotId !== store.getters['auth/getUser'].id && !isBidder) return router.push({ name: 'login' })
      } catch (e) {
        return router.push({ name: 'login' })
      }
    } else {
      isEdit.value = false
    }
  })

  const getLotCP = computed(() => {
    return store.getters['lot/getLot']
  })

  async function getCurrentLot () {
    const id = router.currentRoute.value.params.id
    let data
    if (router.currentRoute.value.query.integration) {
      data = await integrationController.getLot(id)
    } else {
      const response = await getLot(id)
      data = response.data
    }
    store.commit('lot/setLot', data)
  }

  watch(getLotCP, () => {
    for (const [key] of Object.entries(formData)) {
      if (listKey.has(key)) {
        listKey.get(key)()
      } else {
        formData[key] = getLotCP.value[key]
      }
    }
  })

  return {
    listKey,
    getLotCP
  }
}
