import store from '@/store'
import { ManualController } from '@/controllers/ManualController'

export async function manualMemoization (isAuth = true, key) {
  if (!key) return []

  const manualController = new ManualController()
  if (!store.getters['manual/getManualByKey'](key).length) {
    await manualController.setManualByKey({ isAuth, key })
  }

  return store.getters['manual/getManualByKey'](key)
}
