<template>
  <div class="form__flex form__flex-start">
    <div :class="[!isSplit && 'form__left']">

      <div class="field_criterion">
        <VSelect
          name="currency_id"
          id="currency_id"
          :options="currency"
          classes="select-border-bottom"
          class="input-long"
          v-model="formData.currency_id"
          :errors="v$.currency_id.$errors"
          :server-errors="serverErrors.value?.currency_id"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          placeholder="Валюта закупки"
        />

        <VCheckbox
          :class-form="['form-checkbox mb-20 small-checkbox']"
          id="is_criterion_valute"
          name="is_criterion_valute"
          class-label="label"
          v-if="!isBidder"
          :value="formData.is_criterion_valute"
          v-model="formData.is_criterion_valute"
        >
          <template #default="slotProps">
            <label :for="slotProps.for" :class="slotProps.class">
              Критерий
            </label>
          </template>
        </VCheckbox>
      </div>

      <div class="field_criterion">
        <VSelect
         name="payment_term_id"
         id="payment_term_id"
         :options="paymentOptions"
         classes="select-border-bottom"
         class="input-long"
         v-model="formData.payment_term_id"
         @blur="validateField"
         @input="validateField"
         :disabled="disabledPaymentTerm"
         placeholder="Условия оплаты"
        />

        <VCheckbox
          v-if="!isBidder"
          :class-form="['form-checkbox mb-20 small-checkbox']"
          id="is_criterion_payment"
          name="is_criterion_payment"
          class-label="label"
          :value="formData.is_criterion_payment"
          v-model="formData.is_criterion_payment"
          :disabled="formData.is_criterion_deley_max"
        >
          <template #default="slotProps">
            <label :for="slotProps.for" :class="slotProps.class">
              Критерий
            </label>
          </template>
        </VCheckbox>

      </div>

      <div class="field_criterion field_criterion-between">
        <VInput
          v-if="!isBidder"
          name="deley_min"
          id="deley_min"
          type="number"
          v-model="formData.deley_min"
          :errors="v$.deley_min.$errors"
          :server-errors="serverErrors.value?.deley_min"
          @blur="validateField"
          @input="validateField"
          :disabled="(isBidder ? !isEdit : false) || minCriterionDisabled || disabledCriterionDelay"
          class-form="form__control-length-350"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Минимальное зн отсрочки"
        />

        <VInput
          v-if="!isBidder"
          name="deley_max"
          id="deley_max"
          type="number"
          v-model="formData.deley_max"
          :errors="v$.deley_max.$errors"
          :server-errors="serverErrors.value?.deley_max"
          @blur="validateField"
          @input="validateField"
          :disabled="disabledDelayMax || disabledCriterionDelay"
          class="form__control-length-350"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          :placeholder="isBidder ? 'Значение отсрочки' : 'Максимальное зн отсрочки'"
        />
      </div>

      <VCheckbox
        v-if="!isBidder"
        v-model="formData.is_criterion_deley_max"
        class-form="mb-20"
        id="is_criterion_deley_max"
        name="is_criterion_deley_max"
        class-label="label"
        :disabled="disabledCriterionDelay"
        :value="formData.is_criterion_deley_max"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Максимальное значение отсрочки как критерий
          </label>
        </template>
      </VCheckbox>

      <VCheckbox
        v-if="!isBidder"
        id="payment_ability_edit"
        name="payment_ability_edit"
        class-form="form-checkbox"
        :is-switch="true"
        v-model="formData.payment_ability_edit"
        class-label="bg-white"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Редактируемые поставщиком условия оплаты
          </label>
        </template>
      </VCheckbox>
    </div>

    <div :class="[!isSplit && 'form__right']">
      <transition name="slide-fade">
        <VInput
          v-if="formData.isNds"
          name="nds_counter"
          id="nds_counter"
          type="number"
          v-model="formData.nds_counter"
          :errors="v$.nds_counter.$errors"
          :server-errors="serverErrors.value?.nds_counter"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Процентная ставка НДС"
        />
      </transition>

      <VInput
        v-if="isBidder"
        name="deley_max"
        id="deley_max"
        type="number"
        v-model="formData.deley_max"
        :errors="v$.deley_max.$errors"
        :server-errors="serverErrors.value?.deley_max"
        @blur="validateField"
        @input="validateField"
        :disabled="disabledDelayMax || disabledCriterionDelay"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        :placeholder="isBidder ? 'Значение отсрочки' : 'Максимальное зн отсрочки'"
      />

      <VCheckbox
        v-if="!isBidder"
        id="isNds"
        name="isNds"
        v-model="formData.isNds"
        :server-errors="serverErrors.value?.isNds"
        :is-switch="true"
        @input="changeNds"
        class-label="bg-white"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Включить НДС
          </label>
        </template>
      </VCheckbox>
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject, reactive, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'

import VSelect from '@/components/ui/form/VSelect'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VInput from '@/components/ui/form/VInput'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { CONDITIONS_PAYMENT, CURRENCY } from '@/utils/manual/manualList'
import { findById } from '@/utils/array'

const store = useStore()

const props = defineProps({
  isBidder: {
    type: Boolean,
    required: false
  },
  isEdit: {
    type: Boolean,
    required: false
  },
  maxCriterionDisabled: {
    type: Boolean,
    required: true
  },
  minCriterionDisabled: {
    type: Boolean,
    required: true
  },
  isSplit: {
    type: Boolean,
    required: false
  }
})

const formData = inject('formData')
const v$ = inject('v$')
const serverErrors = inject('serverErrors')
const validateField = inject('validateField')

const currency = reactive([])
const payment = reactive([])

const paymentOptions = computed(() => {
    let isSMR = false
    let paymentArr = []
    formData.categories.forEach(item => {
        if (item.is_construction === true) isSMR = true 
    })
    if (isSMR && (formData.procedure_type_id === 2 || formData.procedure_type_id === 3)) paymentArr = payment
    else paymentArr = payment.filter(item => item.id !== 6)
    return paymentArr
})

onMounted(async () => {
  currency.push(...await manualMemoization(true, CURRENCY))
  payment.push(...await manualMemoization(true, CONDITIONS_PAYMENT))

  if (formData.currency_id && store.getters['auth/isBidder']) {
    setCurrentCurrency()
  }
})

const changeNds = () => {
    if (formData.isNds) formData.nds_counter = 20
    else formData.nds_counter = 0
}

const disabledDelayMax = computed(() => {
  let isDisabled = false
  if (props.isBidder) {
    isDisabled = !props.isEdit || formData.is_criterion_payment
  }

  return isDisabled || props.maxCriterionDisabled
})
const disabledPaymentTerm = computed(() => {
  let isDisabled = false
  if (props.isBidder) {
    isDisabled = !props.isEdit
  }

  return isDisabled || formData.is_criterion_deley_max
})

const disabledCriterionDelay = computed(() => {
  return formData.is_criterion_payment || formData.payment_term_id
})

if (store.getters['auth/isBidder']) {
  watch(() => formData.currency_id, setCurrentCurrency)
}

function setCurrentCurrency () {
  const currentCurrency = findById(formData.currency_id, currency)
  store.commit('lot/setCurrentCurrency', currentCurrency)
}
</script>
