import { Request } from '@/service/api/Request/Request'
import { IntegrationService } from '@/service/api/IntegrationService'

export class IntegrationController extends Request {
  #integrationService = new IntegrationService()

  async getLot (id) {
    try {
      return await this.#integrationService.getLot(id)
    } catch (e) {
      await this.handlerErrors.showAlert(e)
    }
  }
}
