import axios from 'axios'

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${process.env.VUE_APP_DATADATA_API_KEY}`
  }
})

export class UrlServices {
  static baseUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
  static fiasIdUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address'
}
