import { helpers, minLength, requiredIf } from '@vuelidate/validators'
import { requiredValid } from '@/utils/validator/fields'
import { computed, watch, reactive } from 'vue'
import router from '@/router'

export function rulesLotCreate(formData, isEdit) {
  const isBidder = router.currentRoute.value.path.includes('bidder')

  const deleyRules = () => {
    const min = isBidder ? +formData.deley_min || 0 : 0
    const max = isBidder ? +formData.deley_max || 0 : 180
    return {
      diapason: helpers.withMessage(`Минимальное значение не может быть меньше ${min} и больше ${max}`, (val) => +val <= max && +val >= min),
      qeual: helpers.withMessage('Минимальное значение не может быть больше максимального', () => {
        return +formData.deley_min <= (isBidder ? +formData.deleyMaxForBidder : +formData.deley_max)
      })
    }
  }
  const getDeleyMin = computed(() => {
    return formData.deley_min
  })

  watch(getDeleyMin, () => {
      rules.deley_min = deleyRules()
      rules.deley_max = deleyRules()
  })

  const rules = reactive({
    started_at: {
      later: helpers.withMessage(
        'Дата старта должна быть не позже, чем за 10 минут до окончания',
        () => {
          if (!formData.duration) return true
          const duration = new Date(formData.duration)

          const currentDate = new Date(
            Date.UTC(
              duration.getUTCFullYear(),
              duration.getUTCMonth(),
              duration.getUTCDate(),
              duration.getUTCHours(),
              duration.getUTCMinutes() - 10,
              duration.getUTCSeconds(),
            ),
          ).toISOString()
          return formData.started_at < currentDate
        },
      ),
      minimal: helpers.withMessage(
        'Дата не может быть меньше текущей',
        (val) => {
          if (isEdit.value) return true
          const dateNow = new Date().toISOString()
          return dateNow <= val
        },
      ),
      ...requiredValid,
    },
    construction_start_at: {
        later: helpers.withMessage(
          'Дата начала должна быть не позже, чем за 10 минут до окончания',
          () => {
            if (!formData.construction_finish_at) return true
            const constructionFinish = new Date(formData.construction_finish_at)
  
            const currentDate = new Date(
              Date.UTC(
                constructionFinish.getUTCFullYear(),
                constructionFinish.getUTCMonth(),
                constructionFinish.getUTCDate(),
                constructionFinish.getUTCHours(),
                constructionFinish.getUTCMinutes() - 10,
                constructionFinish.getUTCSeconds(),
              ),
            ).toISOString()
            return formData.construction_start_at < currentDate
          },
        ),
        minimal: helpers.withMessage(
          'Дата не может быть меньше текущей',
          (val) => {
            if (!val) return true
            if (isEdit.value) return true
            const dateNow = new Date().toISOString()
            return dateNow <= val
          },
        ),
    },
    duration: {
      later: helpers.withMessage(
        'Дата окончания должна быть не раньше, чем спустя 10 минут после начала',
        () => {
          if (!formData.duration) return true
          const duration = new Date(formData.duration)

          const currentDate = new Date(
            Date.UTC(
              duration.getUTCFullYear(),
              duration.getUTCMonth(),
              duration.getUTCDate(),
              duration.getUTCHours(),
              duration.getUTCMinutes() - 10,
              duration.getUTCSeconds(),
            ),
          ).toISOString()
          return formData.started_at < currentDate
        },
      ),
      ...requiredValid,
    },
    construction_finish_at: {
        later: helpers.withMessage(
          'Дата окончания должна быть не раньше, чем спустя 10 минут после начала',
          () => {
            if (!formData.construction_finish_at) return true
            const constructionFinish = new Date(formData.construction_finish_at)
  
            const currentDate = new Date(
              Date.UTC(
                constructionFinish.getUTCFullYear(),
                constructionFinish.getUTCMonth(),
                constructionFinish.getUTCDate(),
                constructionFinish.getUTCHours(),
                constructionFinish.getUTCMinutes() - 10,
                constructionFinish.getUTCSeconds(),
              ),
            ).toISOString()
            return formData.construction_start_at < currentDate
          },
        ),
    },
    extension_time: {
      minLength: helpers.withMessage(
        'Укажите время в часах и минутах',
        minLength(5),
      ),
      ...requiredValid,
    },
    step_time: {
      minLength: helpers.withMessage(
        'Укажите время в часах и минутах',
        minLength(5),
      ),
      ...requiredValid,
    },

    name: requiredValid,
    company_id: requiredValid,
    published_at: {
      requiredIf: helpers.withMessage(
        'Обязательное поле',
        requiredIf(() => formData.isPublishedBeforeSave === false),
      ),
    },

    max_step: {
      requiredIf: helpers.withMessage(
        'Обязательное поле',
        requiredIf(() => formData.procedure_type_id === 2),
      ),
    },
    min_step: {
      requiredIf: helpers.withMessage(
        'Обязательное поле',
        requiredIf(() => formData.procedure_type_id === 2),
      ),
    },

    delivery_condition_id: requiredValid,
    delivery_frequency: {
         requiredIf: helpers.withMessage(
        'Обязательное поле',
        requiredIf(() => {
            let isSMR = false
            formData.categories.forEach(item => {
                if (item.is_construction === true) isSMR = true 
            })
            if (isSMR && (formData.procedure_type_id === 2 || formData.procedure_type_id === 3)) return false
            else return true
        })
      ),
    },
    delivery_address: requiredValid,

    currency_id: requiredValid,
    payment_term_id: {},
    nds_counter: {
      requiredIf: helpers.withMessage(
        'Обязательное поле',
        requiredIf(() => formData.isNds),
      ),
    },
    positions: requiredValid,
    analog_acceptable: requiredValid,
    responsible_full_name: requiredValid,
    responsible_phone: {},
    criterion_value: {
      ...requiredValid,
      minLength: helpers.withMessage('Критерий не может быть 0', (val) => {
        return +val > 0
      }),
      equalCriterion: helpers.withMessage(
        'Сумма групп критериев должна быть равна 1',
        () => {
          const sum =
            +formData.criterion_non_value +
            +formData.criterion_skill +
            +formData.criterion_value
          return sum === 1
        },
      ),
    },
    categories: requiredValid,
    criterion_non_value: {
      equalCriterion: helpers.withMessage(
        'Сумма групп критериев должна быть равна 1',
        () => {
          const sum =
            +formData.criterion_non_value +
            +formData.criterion_skill +
            +formData.criterion_value
          return sum === 1
        },
      ),
    },
    criterion_skill: {
      equalCriterion: helpers.withMessage(
        'Сумма групп критериев должна быть равна 1',
        () => {
          const sum =
            +formData.criterion_non_value +
            +formData.criterion_skill +
            +formData.criterion_value
          return sum === 1
        },
      ),
    },
    deley_min: {},
    deley_max: {}
  })

  const correctPrice = (val) => {
    let maxStep = 0
    if (formData.is_procent) {
      maxStep = val / 100
    } else {
      maxStep = val
    }

    return maxStep > 0
  }

  const getProcedureType = computed(() => {
    return formData.procedure_type_id
  })

  watch(getProcedureType, () => {
    if (formData.procedure_type_id === 2) {
      rules.max_step = {
        ...requiredValid,
        min: helpers.withMessage(
          'Максимальное значение не может быть меньше минимального',
          (value) => +value > +formData.min_step,
        ),
        correct: helpers.withMessage(
          'Измените сумму или размер шага',
          (val) => {
            return correctPrice(val)
          },
        ),
      }
      rules.min_step = {
        ...requiredValid,
        correct: helpers.withMessage(
          'Измените сумму или размер шага',
          (val) => {
            return correctPrice(val)
          },
        ),
      }
      formData.min_step = 0.5
      formData.max_step = 40
    }
  })

  if (!isEdit.value && !isBidder) {
    rules.deley_min = deleyRules()
    rules.deley_max = deleyRules()
  }

  if (isBidder) {
    rules.relation_to_products_id = requiredValid
  }

  return rules
}
