<template>
  <VFileList
    class="mb-20"
    :files="getFiles"
    v-if="getFiles?.length && $route.params.id && isShowList"
    :show-delete="!isBidder"
    grid
    @onDelete="handlerDelete"
  />

  <VFile
    class="max-w-initial mb-60"
    id="supportingDocumentation"
    name="supportingDocumentation"
    multi-file
    grid
    v-if="isCreate"
    v-model="formData.supportingDocumentation"
    :server-errors="serverErrors.value?.supportingDocumentation"
  />
</template>

<script setup>
import { defineExpose, defineProps, defineComponent, computed, inject } from 'vue'
import { useStore } from 'vuex'

import VFile from '@/components/ui/form/File/VFile'
import VFileList from '@/components/list/FileList/VFileList'

const props = defineProps({
  isBidder: {
    type: Boolean,
    required: false
  },
  isCreate: {
    type: Boolean,
    required: false,
    default: true
  },
  formData: {
    type: Object,
    required: false
  },
  serverErrors: {
    type: Object,
    required: false
  },
  isShowList: {
    type: Boolean,
    required: false,
    default: true
  }
})

const store = useStore()

const getFiles = computed(() => {
  return store.getters['files/getFiles']
})

const handlerDelete = (file) => {
  store.commit('files/deleteFile', file)
}

const formData = props.formData ? props.formData : inject('formData')
const serverErrors = props.serverErrors ? props.serverErrors : inject('serverErrors')

defineExpose({
  getFiles,
  handlerDelete
})

defineComponent({
  VFile,
  VFileList
})
</script>
